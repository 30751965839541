import React, {Fragment, useCallback, useEffect, useState} from "react";
import SideDrawer, {SideDrawerProperties} from "../SideDrawer";
import ActivityView from "../chat/ActivityView";
import {firestore} from "../../firebase";
import {Activity, User} from "myfitworld-model";
import ChatDialog from "../chat/ChatDialog";
import {useNavigate} from "@reach/router";
import BirthdayNotificationView from "../chat/BirthdayNotificationView";
import { useNotificationsProvider } from "../../providers/NotificationsProvider";
import LoadMoreButton from "../chat/LoadMoreButton";
import { Box, Button, Typography } from "@material-ui/core";
import DeleteUserNotificationView from "../chat/DeleteUserNotificationView";
import { buildUrl, configMainRoutesConsts } from "../../config/route";
import TrainerNotificationView from "../chat/TrainerNotificationView";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { useMfwThemeProvider } from "myfitworld-utils";
import { useIntl } from "react-intl";
import globalMessages from '../../messages';

const  NotificationsDrawer = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [userName, setUserName] = useState<string>();
  const {allNotifications, markAsReadAllNotifications, unreadCount, setLimit, limit} = useNotificationsProvider();
  const {theme} = useMfwThemeProvider();
  const {formatMessage} = useIntl();
  const handleDialogOpen = (userId: string) => {
    firestore.collection('users').doc(userId).get().then(response => {
      setUserId(userId);
      setUserName(`${(response.data() as User).firstName} ${(response.data() as User).lastName}`)
    })
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onViewDetails = useCallback(
    (activity: Activity) => {
      activity.workoutId ?
      navigate(
        buildUrl(
          configMainRoutesConsts.clientStatisticPage,
          [
            activity.threadId,
            activity.workoutId
          ]
        )
      )
       :
       navigate(
        buildUrl(
          configMainRoutesConsts.clientDailyActivityInfo,
          [
            activity.threadId,
            activity.id || ""
          ]
        )
      )
      onClose();
    },
    [onClose, navigate]
  );

  useEffect(() => {
    if(unreadCount > 0){
      markAsReadAllNotifications();
    }
  }, []);
  
  const loadMoreNotifications = () => {
    setLimit((prevState: number) => prevState + 10);
  }

 
  return (
    <Fragment>
      <SideDrawer isOpen={isOpen} onClose={onClose}>
        <Box display='flex' justifyContent='flex-end'>
          <Button variant="outlined" onClick={() => 
              {
                navigate('/notification-center');
                onClose();
              }
            }
          >
            <SettingsOutlinedIcon/>
            <Typography variant="body1" style={{marginLeft: 2}}>{formatMessage(globalMessages.notification_center)}</Typography>
          </Button>
        </Box>
        <div>
          {allNotifications.length === 0 && 
            <Typography variant='h3'>You don't have notifications.</Typography>
          }
          {allNotifications !== undefined &&
          allNotifications.map((activity, index) =>
            activity.type === "BIRTHDAY_NOTIFICATION" ? 
            <BirthdayNotificationView
                activity={activity}
                key={activity.id}
                onSendHappyBirthday={() => handleDialogOpen(activity.threadId)}
            />
            : activity.type === "DELETE_USER" ? 
            (
              <DeleteUserNotificationView activity={activity} />
            ) 
            : activity.type === "TRAINER_NOTIFICATION" ?
            (
              <TrainerNotificationView activity={activity}/>
            )
            :
            (
              <ActivityView activity={activity} key={activity.id} onViewDetails={() => onViewDetails(activity)} />
            )
          )
          }
           {
            allNotifications.length >= limit && 
            <LoadMoreButton onPress={loadMoreNotifications} />
          }
        </div>
      </SideDrawer>
      {isOpenDialog && userId && userName &&
      <ChatDialog isOpen={isOpenDialog} handleClose={handleDialogClose} userId={userId} userName={userName}/>
      }
    </Fragment>
  );
};

export default NotificationsDrawer;

interface Props extends SideDrawerProperties {}

const getActivityRef = (id: string) => firestore.collection("activity").doc(id);
