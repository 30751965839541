import { useCallback, useEffect, useState } from "react";
import { useQuery } from "../base";
import globalState, { GlobalState } from "../../globalState";
import { Invitation, Role, User } from "myfitworld-model";
import { useIntl } from "react-intl";
import organizationsMessages from "./messages";
import { fetchOrganizationInvitations } from "../../api/firebaseQueries/Organizations/invitations";
import sortBy from "lodash/sortBy";
import deburr from "lodash/deburr";

function useOrganizationInvitations({
  userId,
  organizationId,
  role,
  roles,
  isWhiteLabel
}: {
  userId?: string;
  organizationId?: string;
  role: Role|undefined,
  roles: Role[],
  isWhiteLabel: boolean
}) {
  const {formatMessage} = useIntl();

  const fetchOrganizationInvitationsListQuery = useCallback(async () => {
    try {
      if (!organizationId || !userId) return [];
      return fetchOrganizationInvitations({ userId, organizationId, role, roles, isWhiteLabel});
    } catch (error) {
      globalState.update((state: GlobalState) => {
        state.toastQueue.push({
          message: formatMessage(organizationsMessages.error_loading_Organization_Clients),
          severity: "error",
        });
      });
    }
  }, [organizationId, role, roles, userId]);

  const { data, status, error, isLoading } = useQuery(fetchOrganizationInvitationsListQuery);

  useEffect(() => {
    if (data) {
      const sortedInvitations = sortBy(data, [
        (invitation) => deburr(invitation.lastName).toLowerCase(),
        (invitation) => deburr(invitation.firstName).toLowerCase()
      ]);
    

      setFilteredInvitations(sortedInvitations);
    }
  }, [data]);

  const [filteredInvitations, setFilteredInvitations] = useState<Invitation[]>([]);



  return {
    organizationInvitations: filteredInvitations,
    setInvitations: setFilteredInvitations,
    statusFetchingInvitationsList: status,
    errorFetchingInvitationsList: error,
    isLoadingInvitationsList: isLoading,
  };
}

export default useOrganizationInvitations;
