import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
      table: {
        minWidth: 650,
        marginTop: 30
      },
      tableRow: {
        '& > *': {
          whiteSpace: 'nowrap',
          padding: 10
        },
        cursor: 'pointer'
      },
      userTableRow: {
        '& > *': {
          whiteSpace: 'nowrap',
          padding: 20
        }
      },
      tableActionBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },
      box_chat:{
        display:"flex", 
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end"
      }
    }
  )
);
