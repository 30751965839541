import React, {useEffect, useMemo, Suspense, lazy } from "react";
import {Redirect, Router, navigate} from "@reach/router";
import AppLayout from "./components/AppLayout";
import {Box, CircularProgress, createStyles, CssBaseline, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Snackbar from "./components/snackbar/Snackbar";
import TopBarLoadingIndicator from "./components/TopBarLoadingIndicator";
import {LanguageProvider} from "./components/language/LanguageProvider";
import Role from "myfitworld-model/dist/enums/Role";
import {OrganizationProvider} from "./providers/OrganizationProvider";
import {auth} from "./firebase";
import {useUserProvider} from "./providers/UserProvider";
import {getCurrentUserRole} from "./api/usersApi";
import ExerciseProvider from "./providers/ExerciseProvider";
import {OrganizationsProvider} from "./providers/OrganizationsProvider";
import {Organization} from "myfitworld-model";
import {useStoreState} from "pullstate";
import globalState from "./globalState";
import { setLastOnlineTimeOrganization } from "./api/organizationsApi";
import ReactPixel from 'react-facebook-pixel';
import { useMfwThemeProvider } from "myfitworld-utils";
import { configMainRoutesConsts } from "./config/route";
import LoadingSpinner from "./components/LoadingSpinner";
import ConfigurationManagementPage from "./pages/configuration/ConfigurationManagementPage";
import MyOrganizationPage from "./pages/organization/MyOrganizationPage";
// import useChatWidget from "./hooks/useChatWidget";

const ContentManagementPage   = lazy(() => import("./pages/content/ContentManagementPage"));
const LoginContainer          = lazy(() => import("./pages/auth/LoginContainer"));
const NutritionPlanPage       = lazy(() => import("./pages/nutrition/NutritionPlanPage"));
const InvitationPage          = lazy(() => import("./pages/auth/InvitationPage"));
const ClientFormContainerPage = lazy(() => import("./pages/clients/ClientFormContainerPage"));
const ClientLandingPage       = lazy(() => import("./pages/premium/ClientLandingPage"));
const SchedulePage            = lazy(() => import("./pages/schedule/SchedulePage"));
const ClientStatisticPage     = lazy(() => import("./pages/clients/ClientStatisticPage"));
const PublicProfilePage       = lazy(() => import("./pages/profile/PublicProfilePage"));
const EditProfilePage         = lazy(() => import("./pages/profile/EditProfilePage"));
const EditOrgProfilePage      = lazy(() => import("./pages/profile/EditOrgProfilePage"));
const InvitationInfoPage      = lazy(() => import("./pages/InvitationInfoPage"));
const BillingPage             = lazy(() => import("./pages/billing/BillingPage"));
const PublicOrgProfilePage    = lazy(() => import("./pages/profile/PublicOrgProfilePage"));
const ClientRichStatisticPage = lazy(() => import("./pages/clients/ClientRichStatisticPage"));
const CorpOrganizationPage    = lazy(() => import("./pages/organization/CorpOrganizationPage"));
const PremiumSuperadminPage = lazy(() => import("./pages/premiumSuperadmin/PremiumSuperadminPage"));
const ReportsManagementPage   = lazy(() => import("./pages/reports/ReportsManagementPage"));
const ClientMetricHistory     = lazy(() => import("./pages/clients/ClientMetricHistory"));
const CreateUserAccount       = lazy(() => import("./pages/auth/CreateUserAccount"));
const ClientDailyActivityInfo = lazy(() => import("./pages/clients/ClientDailyActivityInfo"));
const StripeConnectPage       = lazy(() => import("./pages/billing/stripeConnectPage/StripeConnectPage"));
const CreateAdminAccount      = lazy(() => import("./pages/auth/CreateAdminAccount"));
const TutorialsPage           = lazy(() => import("./pages/tutorials/TutorialsPage"));
const EmailResetPasswordPage  = lazy(() => import("./pages/auth/EmailResetPasswordPage"));
const MarketplaceProductPage  = lazy(() => import("./components/marketplace/marketplaceProductPage/MarketplaceProductPage"));

const OrganizationsManagementPage     = lazy(() => import("./pages/organizations/OrganizationsManagementPage"));
const ClientManagementPage            = lazy(() => import("./pages/clients/ClientManagementPage"));
const SuperAdminUserManagementPage    = lazy(() => import("./pages/users/SuperAdminUserManagementPage"));
const InvitationFormContainerPage     = lazy(() => import("./pages/clients/InvitationFormContainerPage"));
const TrainerOrganizationLoginPage    = lazy(() => import("./pages/auth/TrainerOrganizationLoginPage"));
const CorpOrganizationsManagementPage = lazy(() => import("./pages/organizations/CorpOrganizationsManagementPage"));
const MarketplaceManagementPage       = lazy(() => import("./pages/marketplace/MarketplaceManagementPage"));

const PrivacyPolicyAndTermsOfServicesPage = lazy(() => import("./pages/pp-tos/PrivacyPolicyAndTermsOfServicesPage"));
const NotificationPage = lazy(() => import("./pages/notification-center/NotificationCenterPage"));
export const isStageEnv = process.env.REACT_APP_ENV === "stage" 

const useGlobalStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingSplashScreen: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    root: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
    },
  })
);

declare interface Route {
  path: string;
  Component: React.ElementType;
  condition?: (org?: Organization) => void;
}

const superAdminRoutes : Route[] = [
  {
    path: configMainRoutesConsts.organizations,
    Component: OrganizationsManagementPage,
  },{
    path: configMainRoutesConsts.nutritionPlan,
    Component: NutritionPlanPage,
  },
  {
    path: configMainRoutesConsts.users,
    Component: SuperAdminUserManagementPage,
  },
  {
    path: configMainRoutesConsts.corporateOrganizations,
    Component: CorpOrganizationsManagementPage,
  },
  {
    path: configMainRoutesConsts.superadminReports,
    Component: ReportsManagementPage,
  },
  {
    path: configMainRoutesConsts.corpClientsCorpId,
    Component: CorpOrganizationPage,
  },
  {
    path: configMainRoutesConsts.premium,
    Component: PremiumSuperadminPage
  },
  {
    path: configMainRoutesConsts.marketplace,
    Component: MarketplaceManagementPage,
  }
];

const trainerRoutes : Route[] = [
  {
    path: configMainRoutesConsts.schedule,
    Component: SchedulePage,
  },
  {
    path: configMainRoutesConsts.content,
    Component: ContentManagementPage,
  },{
    path: configMainRoutesConsts.nutritionPlan,
    Component: NutritionPlanPage,
  },
  {
    path: configMainRoutesConsts.clients,
    Component: ClientManagementPage,
  },
  {
    path: configMainRoutesConsts.profile,
    Component: EditProfilePage,
  },
  {
    path: configMainRoutesConsts.tutorials,
    Component: TutorialsPage,
  },
  {
    path: configMainRoutesConsts.marketplace,
    Component: MarketplaceManagementPage,
  }
];

const adminRoutes : Route[] = [
  ...trainerRoutes,
  {
    path: configMainRoutesConsts.orgProfile,
    Component: EditOrgProfilePage,
  },
  {
    path: configMainRoutesConsts.configuration,
    Component: ConfigurationManagementPage,
  },
  {
    path: configMainRoutesConsts.organization,
    Component: MyOrganizationPage
  },
  {
    path: configMainRoutesConsts.billing,
    Component: BillingPage,
  },
  {
    path: configMainRoutesConsts.stripeConnect,
    Component: StripeConnectPage,
  },
  {
    path: configMainRoutesConsts.marketplace,
    Component: MarketplaceManagementPage,
  }
];

const routesFor = (role?: Role, currentOrganization?: Organization): Array<Route> => {
  let routes: Route[] = [];
  if (role === Role.SuperAdmin) {
    routes = superAdminRoutes;
  } else if (role === Role.Admin) {
    routes = adminRoutes;
  } else if (role === Role.Trainer || role === Role.AssistantTrainer) {
    routes = trainerRoutes;
  }

  return routes.filter(r => {
    if (r.condition) {
      return r.condition(currentOrganization);
    }
    return true;
  });
};

const getRedirectRoute = (role: Role, organizationOverride: string | undefined) => {
  if (organizationOverride) {
    return configMainRoutesConsts.organization;
  } else if (role === Role.SuperAdmin) {
    return configMainRoutesConsts.organizations;
  } else if (role === Role.Trainer || role === Role.AssistantTrainer || role === Role.Admin) {
    return configMainRoutesConsts.schedule
  } else {
    return configMainRoutesConsts.content;
  }
};

function FacebookPixel() {
  React.useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('252880413314730');
        ReactPixel.pageView();
      });
  });
  return null;
}

function App() {
  const isWhiteLabel : boolean = useMfwThemeProvider().theme.isWhiteLabel;
  useGlobalStyles();
  // useChatWidget(isWhiteLabel);
  const classes = useStyles();
  const {user, organizationOverride, loading, enterApplication} = useUserProvider();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);

  const advancedMatching = undefined      //Values will be hashed automatically by the pixel using SHA-256

// }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

ReactPixel.init('252880413314730', advancedMatching, options)


  const currentUserRole = useMemo(() => {
    return user ? getCurrentUserRole(user, organizationOverride) : undefined;
  }, [user, organizationOverride]);

  const updateLastOnlineTimeForOrganizations = () => {
    user?.organizations?.forEach((org) => {
      if (org.role !== Role.Client) {
        setLastOnlineTimeOrganization(org.id);
      }
    });
  };

  useEffect(() => {
    if (window.location.pathname === configMainRoutesConsts.signout) {
      const language = user?.language;
      auth.signOut().then(
        result => {
          navigate(`/${language}`);
        }
      )
    }
  }, [user]);

  useEffect(() => {
    updateLastOnlineTimeForOrganizations();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateLastOnlineTimeForOrganizations();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [user]);
  
  const routes = isWhiteLabel ? routesFor(currentUserRole, currentOrganization).filter((r)=>r.path!==configMainRoutesConsts.tutorials) : routesFor(currentUserRole, currentOrganization);

  if (loading) {
    return (
      <Box flex={1} className={`${classes.root} ${classes.loadingSplashScreen}`}>
        <CircularProgress/>
      </Box>
    );
  }

  return (
    <>
      <Suspense fallback={<LoadingSpinner marginTop={100} />}>
        <FacebookPixel />
        <CssBaseline />
        <Box flex={1} className={classes.root}>
          <Snackbar />
          <TopBarLoadingIndicator />
          <LanguageProvider>
            {user ? (
              currentUserRole === Role.Client || currentUserRole === undefined ? (
                <AppLayout>
                  <Router>
                    <PublicProfilePage path={configMainRoutesConsts.publicProfilePage} />
                    <PublicOrgProfilePage path={configMainRoutesConsts.publicOrgProfilePage} />
                    <PublicOrgProfilePage path={configMainRoutesConsts.marketplacePublicOrgProfilePage} />
                    <PublicProfilePage path={configMainRoutesConsts.marketplacePublicProfilePage} />
                    <MarketplaceManagementPage path={configMainRoutesConsts.marketplace} />
                    <MarketplaceProductPage path={configMainRoutesConsts.marketplaceProductPage} />
                    <ClientLandingPage path={configMainRoutesConsts.rootAny} />
                  </Router>
                </AppLayout>
              ) : (
                <OrganizationsProvider>
                  <OrganizationProvider>
                    <ExerciseProvider>
                      {currentUserRole ? (
                        enterApplication ? (
                          <AppLayout>
                            <Router>
                              {routes.map(({ path, Component }: Route, i: number) => (
                                <Component key={i} path={path} />
                              ))}
                              <InvitationPage path={configMainRoutesConsts.invitationPage} />
                              <ClientFormContainerPage path={configMainRoutesConsts.clientFormContainerPage} />
                              <InvitationFormContainerPage path={configMainRoutesConsts.invitationFormContainerPage} />
                              <ClientStatisticPage path={configMainRoutesConsts.clientStatisticPage} />
                              <ClientDailyActivityInfo path={configMainRoutesConsts.clientDailyActivityInfo} />
                              <ClientRichStatisticPage path={configMainRoutesConsts.clientRichStatisticPage} />
                              <ClientMetricHistory path={configMainRoutesConsts.clientMetricHistory} />
                              <PublicProfilePage path={configMainRoutesConsts.publicProfilePage} />
                              <PublicOrgProfilePage path={configMainRoutesConsts.publicOrgProfilePage} />
                              <PublicOrgProfilePage path={configMainRoutesConsts.marketplacePublicOrgProfilePage} />
                              <PublicProfilePage path={configMainRoutesConsts.marketplacePublicProfilePage} />
                              <MarketplaceManagementPage path={configMainRoutesConsts.marketplace} />
                              <MarketplaceProductPage path={configMainRoutesConsts.marketplaceProductPage} />
                              <PrivacyPolicyAndTermsOfServicesPage
                                path={configMainRoutesConsts.privacyPolicyAndTermsOfServicesPage}
                              />
                              <NotificationPage path={configMainRoutesConsts.notificationCenter}/>
                              <Redirect
                                from={configMainRoutesConsts.rootAny}
                                to={getRedirectRoute(currentUserRole, organizationOverride)}
                                noThrow
                              />
                            </Router>
                          </AppLayout>
                        ) : (
                          <Router></Router>
                        )
                      ) : (
                        <Router>
                          <InvitationPage path={configMainRoutesConsts.invitationPage} />
                          <InvitationInfoPage path={configMainRoutesConsts.rootAny} />
                        </Router>
                      )}
                    </ExerciseProvider>
                  </OrganizationProvider>
                </OrganizationsProvider>
              )
            ) : (
              <Router>
                <InvitationPage path={configMainRoutesConsts.invitationPage} />
                <TrainerOrganizationLoginPage path={configMainRoutesConsts.trainerOrganizationLoginPage} />
                <LoginContainer path={configMainRoutesConsts.languageAttribute} />
                <LoginContainer path={configMainRoutesConsts.root} />
                {/* <WbffTrainerOrganizationLoginPage path="/wbff/signup" /> */}
                <CreateUserAccount path={configMainRoutesConsts.createUserAccount} />
                <CreateAdminAccount path={configMainRoutesConsts.createAdminAccount} />
                <PublicProfilePage path={configMainRoutesConsts.publicProfilePage} />
                <PublicOrgProfilePage path={configMainRoutesConsts.publicOrgProfilePage} />
                <PublicOrgProfilePage path={configMainRoutesConsts.marketplacePublicOrgProfilePage} />
                <PublicProfilePage path={configMainRoutesConsts.marketplacePublicProfilePage} />
                <MarketplaceManagementPage path={configMainRoutesConsts.marketplace} />
                <MarketplaceProductPage path={configMainRoutesConsts.marketplaceProductPage} />
                <EmailResetPasswordPage path={configMainRoutesConsts.emailResetPasswordPage} />
                <PrivacyPolicyAndTermsOfServicesPage
                  path={configMainRoutesConsts.privacyPolicyAndTermsOfServicesPage}
                />
              </Router>
            )}
          </LanguageProvider>
        </Box>
      </Suspense>
    </>
  );
}

export default App;
