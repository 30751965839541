import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    my_schedule: {
        id: 'My Schedule',
        defaultMessage: 'My Schedule'
      },
      content: {
        id: 'Content',
        defaultMessage: 'Content'
      },
      nutrition_plan: {
        id: 'nutrition_plan.nutrition_plan',
        defaultMessage: 'Nutrition plan'
      },
      clients: {
        id: 'Clients',
        defaultMessage: 'Clients'
      },
      my_profile: {
        id: 'my_profile',
        defaultMessage: 'My Profile'
      },
      tutorials: {
        id: 'tutorials',
        defaultMessage: 'Tutorials'
      },
      configuration: {
        id: 'Configuration',
        defaultMessage: 'Configuration'
      },
      my_org: {
        id: 'My Org',
        defaultMessage: 'My Organization'
      },
      org_profile: {
        id: 'org_profile',
        defaultMessage: 'Organization Profile'
      },
      billing: {
        id: 'Billing',
        defaultMessage: 'Billing'
      },
      users: { 
        id: 'Users',
        defaultMessage: 'Users'
      },
      corpOrg: {
        id: 'corporate.corpOrg',
        defaultMessage: 'Corporate'
      },
      reports: {
        id: 'reports.reports',
        defaultMessage: 'Reports'
      },
      organizations: {
        id: 'Organizations',
        defaultMessage: 'Organizations'
      },
      stripe_connect : {
        id: 'Stripe Connect',
        defaultMessage: 'Stripe Connect'
      },
      marketplace : {
        id: 'marketplace',
        defaultMessage: 'Marketplace'
      },
      home : {
        id: 'home',
        defaultMessage: 'Home'
      },
      premium: {
        id: 'premium',
        defaultMessage: 'PREMIUM subscriptions'
      }
})

export default messages;
