import {  defineMessages } from 'react-intl'

const messages = defineMessages({
  archive_chat: {
    id: 'button.archive_chat',
    defaultMessage: 'Archive Chat'
  },
  unarchive_chat: {
    id: 'button.unarchive_chat',
    defaultMessage: 'Unrchive Chat'
  },
  delete_chat: {
    id: 'button.delete_chat',
    defaultMessage: 'Delete Chat'
  },
  archive_chat_questions: {
    id: 'chat.archive_chat_questions',
    defaultMessage: 'Are you sure you want to archive this chat? '
  },
  unarchive_chat_questions: {
    id: 'chat.unarchive_chat_questions',
    defaultMessage: 'Are you sure you want to unarchive this chat? '
  },
  delete_chat_questions: {
    id: 'chat.delete_chat_questions',
    defaultMessage: 'Are you sure you want to delete this chat? '
  }
})

export default messages;
