import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Exercise} from "myfitworld-model";
import {Box, Card, CardContent, CardHeader, createStyles, Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import getLocalizedProperty from "../../../utils/getLocalizedProperty";
import getLocalizedDescriptionWithOriginalFormat from '../../../utils/getLocalizedDescriptionWithOriginalFormat';
import isEmpty from 'lodash/isEmpty';
import { formatYoutubeLink } from '../../universalFunctions';
import globalMessages from '../../../messages';
import pageMessages from '../../messages';
import { useIntl } from 'react-intl';
// const dialogWidth = 600;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton:{
      color: theme.palette.text.primary
    },
    root: {
      // width: dialogWidth,
      width: "100%",
      overflow: "auto"
    },
    media: {
      // height: dialogWidth * 9 / 16,
      height: "auto",
      maxWidth: "100%",
    },
    videoLink: {
      display: 'flex',
      justifyContent: 'center',
      height: "auto",
      maxWidth: "100%",
    },
    content: {
      padding: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    desc: {
      lineHeight: 2.5,
    },
    descContainer:{
      backgroundColor:theme.palette.background.paper,
      borderRadius:10,
      padding:10,
    },
    span: {
      color: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.text.primary,
    },
  }),
);
export function renderLocalizedDescription(description: string): JSX.Element {
  return (
    <Typography dangerouslySetInnerHTML={{ __html: description }} />
  );
}
export function renderLocalizedDescriptionHTML(description: string): JSX.Element {
  const paragraphs = description.split('\n').map((paragraph, index) => (
    <React.Fragment key={index}>
      {paragraph}
      <br />
    </React.Fragment>
  ));

  return <Typography>{paragraphs}</Typography>;
}

export default function ExercisePreviewDialog({open, exercise, onClose}: {
  open: boolean,
  exercise: Exercise | null,
  onClose: () => void
}) {
  const classes = useStyles();
  const {formatMessage, locale} = useIntl()

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true}>
      <Card className={classes.root}>
        <CardHeader
          action={
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon className={classes.closeButton}/>
            </IconButton>
          }
        />

        { exercise?.videoURL ?
          <video className={classes.media} controls>
            <source src={exercise?.videoURL} type="video/mp4"/>
          </video>
          :
          exercise?.videoLink ?
            <div>
              <iframe
                style={{height: 300, width: '100%'}}
                src={formatYoutubeLink(exercise.videoLink)}
              />
            </div>
            :
            <div style={{height: 100, width: 100}}></div>
        }
        <CardContent className={classes.content}>
          <Box display="flex" justifyContent="space-between" className={classes.title}>
            <Box flex={4}>
              <Typography variant="h3">{getLocalizedProperty(locale, exercise?.title)}</Typography>
            </Box>
            {exercise?.difficultyLevel && 
              <Box flex={2} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
                <Typography className={classes.text}>{formatMessage(pageMessages.training_level)}</Typography>
                <Typography variant="h3" className={classes.text}>
                  <span className={classes.span}>{exercise?.difficultyLevel}</span>&thinsp;&#47;&thinsp;5
                </Typography>
              </Box>
            }
          </Box>
          {(exercise?.description && !isEmpty(exercise?.description)) &&
            <Box>
              <Typography variant="h5" className={classes.desc}>{formatMessage(globalMessages.description)}</Typography>
              <Grid className={classes.descContainer}>
                {renderLocalizedDescription(getLocalizedDescriptionWithOriginalFormat(exercise?.description))}
              </Grid>
            </Box>
          }
        </CardContent>
      </Card>
    </Dialog>
  );
}
