import { Box, Theme, ThemeProviderProps, Typography, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useUserProvider } from '../../providers/UserProvider';
import { Activity } from 'myfitworld-model';
import { DoneAll } from '@material-ui/icons';
import { useMessagesContext } from '../../providers/MessagesProvider';
import LoadingSpinner from '../LoadingSpinner';
import LoadMoreButton from './LoadMoreButton';
import { format } from 'date-fns';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { ProviderThemeType } from 'myfitworld-utils/dist/types/ThemeTypes';

const createStylesForTheme = (theme: ProviderThemeType, isMobile: boolean) =>
  makeStyles(() =>
    createStyles({
        incomingMessageBox: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            backgroundColor: theme.theme.backgroundColor.light,
            width: isMobile ? '80%' : '30%',
            marginTop: '2%',
            padding: '1%',
            borderRadius: 5,
        },
        sendingMessageBox:{
            display:'flex',
            flexDirection:'column',
            width: isMobile ? '80%' : '30%',
            backgroundColor: theme.theme.primary,
            marginTop:'2%',
            padding:'1%', 
            borderRadius:5,
            alignItems:"end",
        }
    })
  );

const MessagesView = ({userId, setIsLoadMore} : {userId:string, setIsLoadMore: (state: boolean) => void}) => {
    const {user} = useUserProvider();
    const messagesCtx = useMessagesContext();
    const theme = useMfwThemeProvider();
    const isMobile = useMediaQuery(theme.mfwMuiWebTheme.breakpoints.down('sm'));
    const useStyles = createStylesForTheme(theme.theme, isMobile);
    const classes = useStyles();
    
    useEffect(() => {
        if(messagesCtx.messagesForEachClient && Object.keys(messagesCtx.messagesForEachClient).length !== 0){
            messagesCtx.messagesForEachClient[userId].messages.sort((a: Activity, b: Activity) => a.createdAt.getTime() - b.createdAt.getTime());
        }
    },[messagesCtx.messagesForEachClient]);

    const loadEarlierMessages = () => {
        setIsLoadMore(true);
        messagesCtx.setCurrentLimit((prevState: number) => prevState + 10);
        messagesCtx.setUserIdForLoad(userId);
    }

    const getTime = (dateTime: Date) => {

        return format(dateTime, "dd-MM-yyyy hh:mm a");
    }
 
    return(
        <Box>
            {
                messagesCtx.messagesForEachClient && Object.keys(messagesCtx.messagesForEachClient).length === 0 && 
                <LoadingSpinner/>
            }
            {
                messagesCtx.messagesForEachClient && messagesCtx.messagesForEachClient[userId] && messagesCtx.messagesForEachClient[userId].messages.length >= messagesCtx.currentLimit && 
                <LoadMoreButton onPress={loadEarlierMessages} />
            }
            {messagesCtx.messagesForEachClient && Object.keys(messagesCtx.messagesForEachClient).length !== 0 && messagesCtx.messagesForEachClient[userId].messages.map((message: Activity) => 
                (
                    <>
                        {message.senderId === user?.id && 
                            <Box display='flex' justifyContent='flex-end' key={message.id}>
                                <Box className={classes.sendingMessageBox}>
                                    <Typography style={{fontSize: 15, whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>{message.text}</Typography>
                                    <Box flexDirection={'row'} display='flex' justifyContent='flex-end'>
                                        <Typography style={{fontSize: 10, marginRight: 2}}>{getTime(message.createdAt)}</Typography>
                                        <DoneAll color={message.read ? 'primary' : 'disabled'} style={{fontSize: 15}}/>
                                    </Box>
                                </Box>
                            </Box>
                        }

                        {message.senderId === userId && 
                            <Box className={classes.incomingMessageBox} key={message.id}>
                                <Typography style={{fontSize: 15, whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>{message.text}</Typography>
                                <Typography style={{fontSize: 10, textAlign: 'right'}}>{getTime(message.createdAt)}</Typography>
                            </Box>
                        }
                    </>
                )
            )}
        </Box>
    );
};
export default MessagesView;
